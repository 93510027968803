import React from "react"
import Layout from "../../Layout"
import Meta from "../../components/Meta"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Spacer from "../../components/Spacer"
import StaticHeadline from "../../components/static/StaticHeadline"
import StaticParagraph from "../../components/static/StaticParagraph"
import StaticContainer from "../../components/static/StaticContainer"
import StaticImage from "../../components/static/StaticImage"

import usePagesImages from "../../hooks/usePagesImages"

export default function Benzinpreis({ data }) {
  const fluid = usePagesImages("benzinpreis.jpg")

  return (
    <Layout>
      <Meta
        title="Benzinpreis in Deutschland"
        description="Wir beantworten die wichtigsten Fragen: Wie setzt sich der Benzinpreis zusammen? Wer bestimmt den Preis an den Tankstellen? Jetzt auf Tankchecker nachlesen!"
        image={fluid.src}
      />
      <Header />
      <main>
        <StaticContainer maxWidth="lg">
          <Spacer />
          <StaticHeadline variant="h1" align="center" gutterBottom>
            Benzinpreis in Deutschland – wie setzt er sich zusammen?
          </StaticHeadline>
          <StaticImage
            fluid={fluid}
            alt="Benzinpreis in Deutschland"
            copyright="Bildnachweis: MATJAZ SLANIC / iStock"
          />
          <StaticParagraph gutterBottom>
            Die Benzinpreise hier in Deutschland schwanken immer mal wieder.
            Sowohl saisonal als auch mehrfach am Tag kann man beobachten, dass
            die Preise für die einzelnen Kraftstoffsorten (Super E5, Super E10,
            Benzin, Diesel, Erdgas/Flüssiggas) teils stark schwanken. Die Gründe
            für die einzelnen Preisspitzen setzten sich aus mehreren Faktoren
            zusammen. Zunächst einmal gibt es immer einen Grundpreis, der für
            alle Tankstellenbetreiber, egal ob freie Tankstelle oder große
            Mineralölkonzerne wie Aral (BP), Shell, Esso, Jet oder Total, gleich
            ist. Im Detail setzt sich der Benzinpreis an der Tankstelle aus
            folgenden Einzelelementen zusammen:
          </StaticParagraph>

          <StaticParagraph gutterBottom>
            <b>Produkteinstandspreis:</b> Das ist im Prinzip der Preis der Ware,
            also des importierten Rohöls. Gekauft und gehandelt wird der
            Treibstoff an der Ölbörse in Rotterdam. Der Preis richtet sich dabei
            nach Angebot und Nachfrage, aber auch nach dem momentanen
            Dollar/Euro-Wechselkurs. Denn Rohöl wird weltweit fast
            ausschließlich in US-Dollar gehandelt. Zudem richtet sich der
            Rohöl-Kurs nach der Nachfrage, den politischen Verhältnissen in den
            ölfördernden Ländern sowie der Konjunktur.
          </StaticParagraph>

          <StaticParagraph gutterBottom>
            <b>Steuern:</b> Das ist in Deutschland der größte Anteil an den
            Benzinkosten. Die Energiesteuer (früher hieß sie Mineralölsteuer),
            die auch die Ökosteuer beinhaltet, macht zusammen mit der
            Mehrwertsteuer gute 60 Prozent des Spritpreises aus. Als Beispiel:
            Die Energiesteuer bei Benzin liegt bei ca. 65 Cent/Liter.
          </StaticParagraph>

          <StaticParagraph gutterBottom>
            <b>Deckungspreis:</b> Das ist der Anteil, der die Kosten für
            Transport, Weiterverarbeitung, CO2-Abgabe, Bevorratung und Gewinne
            der Mineralölkonzerne abdeckt.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Wer bestimmt den Benzinpreis an der Tankstelle?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Eines ist klar: Die Tankstellenpächter sind es jedenfalls nicht. Sie
            dürfen nicht eigenmächtig die Benzinpreise nach Lust und Laune
            senken oder heben. Vielmehr herrscht unter den Tankstellen einer
            Region ein enormer Preiswettbewerb: Beobachter melden der jeweiligen
            Zentrale regelmäßig die Preise der Wettbewerber und diese
            beschließen dann sehr kurzfristig, ob eine Preisanpassung an den
            eigenen Tankstellen notwendig ist oder nicht. Im Zuge dieser
            Beobachtungen sowie der weiteren Faktoren wie dem
            Dollar/Euro-Umrechnungskurs und dem Börsenkurs des Rohöls werden so
            mehrmals täglich die Spritpreise angepasst. Früher konnte man recht
            sicher davon ausgehen, dass die Preise nachts und am frühen Morgen
            am höchsten waren, nur um dann im Laufe eines Tages immer günstiger
            zu werden. Das ist aber längst überholt. Mittlerweile gibt es
            mehrere starke Preisspitzen und auch Preissenkungen über den Tag
            verteilt. Dennoch ist der Tankpreis zum späten Abend hin oftmals mit
            am günstigsten.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Was bedeutet die 9 im Benzinpreis?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Manch einem wird es schon mal aufgefallen sein: Warum steht da immer
            eine 9 am Ende von jedem Preis auf der Anzeigetafel? Also warum
            steht da nicht für den Benzinpreis nur 1,34 Euro? Warum steht da
            1,349? Das ist noch nicht mal ein Cent mehr – genaugenommen sind es
            0,9 Cent. So ganz genau kann man das nicht mehr nachvollziehen. Am
            wahrscheinlichsten ist die Erklärung, dass ein Wettbewerber vor sehr
            vielen Jahren (noch zu D-Mark-Zeiten) seinen Preis um 1/10 Pfennig
            gesenkt hat, um einen Vorteil gegenüber den anderen
            Tankstellenbetreibern zu haben. Die anderen zogen nach und seitdem
            hat man sich nicht mehr darauf einigen können, diese dritte
            Nachkommastelle gemeinsam zu streichen. Halt eine klassische
            psychologische Verkaufsmethode: Ein Sofa für 1999,99 Euro ist man
            eher bereit zu kaufen als eines für 2000,00 Euro. Ganz genauso
            verhält es sich bei den Benzinkosten. Der Käufer ist eher bereit, an
            der Tankstelle für 1,349 Euro zu kaufen als für 1,35 Euro.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Was beeinflusst den Benzinpreis?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Schwankende Benzin- und Dieselpreise sind normal – der Preis für
            Benzin, Diesel und auch Gas schwankt von Tag zu Tag, ja sogar
            innerhalb eines Tages mehrfach. Gewisse Trends in der
            Preisgestaltung bleiben gleich: Morgens bzw. zur Rush-Hour zwischen
            6 und 9 Uhr sowie zwischen 16 und 18 Uhr sind die Spritpreise in der
            Regel eher höher, wohingegen sie zum späten Abend hin sinken und
            auch während des Tages einige, kurzfristige Preissenkungen zu sehen
            sind. Auch vor Feiertagen oder generell vor und an einem Wochenende
            ist der Benzinpreis in der Regel höher als beispielsweise an einem
            normalen Mittwoch. Doch warum schwanken die Benzinpreise? Zum einen
            hat das sicherlich mit einigen recht verlässlich benennbaren
            Faktoren zu tun: Da wäre zum Beispiel der tagesaktuelle Kurs des
            Dollars. Schwankt dieser, so passt sich auch der Preis für das
            Rohöl, welcher in US-Dollar gehandelt wird, dementsprechend an. Aber
            auch politische Ereignisse oder sogar Naturkatastrophen können den
            Tankpreis beeinflussen. Sehr viel Rohöl wird in politisch nicht ganz
            so stabilen Staaten wie beispielweise Libyen, dem Irak oder Nigeria
            gefördert. Können dort aufgrund von (politischen) Ereignissen
            weniger Rohöl gefördert werden oder werden dadurch die Transportwege
            teurer, so wird durch eine solche Verknappung der Preis für die
            Mineralölkonzerne natürlich teurer und dies wird immer durchgereicht
            an den Endverbraucher. Aber auch Naturkatastrophen wie Hurricanes
            etc. können die Benzinproduktion einbrechen lassen, was letztendlich
            immer zu einer Preissteigerung an den Zapfsäulen führt. Aber
            natürlich haben auch die Mineralölkonzerne einen merkbaren Anteil an
            den Benzinpreis-Schwankungen, da die Konzerne immer maximale Gewinne
            anstreben.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Wenn man mit ein wenig Planung und einer guten Tank-App den
            richtigen Zeitpunkt zum Tanken auswählt, lassen sich so zur
            passenden Tageszeit und an einem günstigen Tag durchaus schon mal
            etliche Cent/Liter sparen.
          </StaticParagraph>
          <Spacer />
          <Spacer />
        </StaticContainer>
      </main>
      <Footer />
    </Layout>
  )
}
